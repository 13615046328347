<template>
    <b-card-code no-body title="Trade Allocation List">
  
      <div class="table-responsive">
        <table class="table b-table">
          <thead>
            <tr>
              <th class="pl-1">
                TA Number
              </th>
              <th class="pl-0">
                Buyer
              </th>
              <th class="pl-0">
                Seller
              </th>
              <th class="pl-0">
                Commodity
              </th>
              <th class="pl-0">
                Price
              </th>
              <th class="pl-0">
                Lot
              </th>
              <th class="pl-0">
                Net Value
              </th>
              <th class="pl-0">
                CDN
              </th>
              <th class="pl-0">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="9" class="text-center">
                <b-spinner
                  class="mb-1"
                  variant="primary"
                /><br>
                Loading...
              </td>
            </tr>
            <tr v-else v-for="item in result.data" v-bind:key="item.id">
              <td class="pl-1">{{ item.ta_number }}</td>
              <td class="pl-0" v-if="item.buyer != null">
                <router-link :to="{ name: 'users.show', params: { id: item.buyer.id } }">
                    {{ item.buyer.code }}<br><small>{{ item.buyer.email }}</small>
                </router-link>
              </td><td v-else>-</td>
              <td class="pl-0" v-if="item.seller != null">
                <router-link :to="{ name: 'users.show', params: { id: item.seller.id } }">
                    {{ item.seller.code }}<br><small>{{ item.seller.email }}</small>
                </router-link>
              </td><td v-else>-</td>
              <td class="pl-0">{{ item.commodity.code }}</td>
              <td class="pl-0">{{ item.price }}</td>
              <td class="pl-0">{{ item.lot }} Lot ({{ item.amount.toFixed(5) }} {{ item.commodity.unit }})</td>
              <td class="pl-0">{{ item.net_value_formatted }}</td>
              <td class="pl-0">
                <ul>
                    <li v-for="(cdn, index) in item.cdn_details" v-bind:key="index">{{ cdn.cdn_number }} <strong>({{ cdn.amount.toFixed(5) }} {{ item.commodity.unit }})</strong></li>
                </ul>              </td>
              <td class="pl-0">
                <button @click="doSettlementTa(item.id)" v-if="!item.is_settlement" class="btn btn-sm btn-success"><feather-icon icon="CheckCircleIcon" />  Settlement</button>
              </td>
            </tr>
            <tr v-if="result.total == 0 && !isLoading">
              <td colspan="9" class="text-center">Data is empty.</td>
            </tr>
          </tbody>
        </table>
  
        <div class="m-1" v-if="result.total > 0">
          <div class="row">
            <div class="col">
              <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
            </div>
            <div class="col">
              <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
            </div>
          </div>
        </div>
      </div>
    </b-card-code>
  </template>
  
  <script>
  import _ from 'lodash'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
  import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
  
  export default {
    title () {
      return `Trade Allocation`
    },
    setup() {
      return {
        checkPermission,
        successNotification,
        errorNotification,
      }
    },
    components: {
      BCard,
      BButton,
      BDropdown,
      BDropdownItem,
      BCardText,
      BCardCode,
      BSpinner,
    },
    watch: {
      filter: {
        handler: _.debounce(function() {                                                                                                                                                              
          this.getData();                                                                                                                                                                        
        }, 0),
        deep: true
      },
    },
    data() {
      return {
        currentPage: 1,
        result: {},
        filter: {},
        isLoading: false,
      }
    },
    mounted() {
      // var context = this
      // this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      //   if (payload.type == 'auction-transaction') {
      //     context.getData(context.currentPage || 1);  
      //   }
      // });
    },
    created() {
      this.getData();
    },
    methods: {
      getData(page = 1) {
  
        // this.isLoading = true;
        this.currentPage = page;
        var queryParams = this.filter
        queryParams.page = page
  
        this.$http.get('/admin/trade-allocations', {
          params: queryParams
        })
        .then(response => {
          this.result = response.data.data
          // this.isLoading = false;
        })
  
      },
      doSettlementTa(taId) {
        this.$swal({
            title: 'Are you sure?',
            text: 'System will settlement this TA',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
            
            this.$http.post('/admin/trade-allocations/' + taId + '/do-settlement')
            .then(response => {

                this.getData(this.currentPage)
                this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Settlement success!',
                customClass: {
                    confirmButton: 'btn btn-success',
                },
                })

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)   
                }
            })

            }
        })
        }
    }
  }
  </script>